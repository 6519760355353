@import "overrides";
// If you want to override variables do it here
@import "variables";

@import 'fonts.scss';

// Import styles
@import "@coreui/coreui/scss/coreui";

// Vendors
@import "vendors/simplebar";

// Custom styles for this theme
@import "theme";

// If you want to add custom CSS you can put it here
@import "custom";

@import 'react-circular-progressbar/dist/styles.css';

