// Here you can add other styles

html {
    background-color: white;
}

.maxviewport {
    max-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;

}

.circularBarBox {
    width: auto;
    height: 100%;
}

.no-padding {
    padding: 0
}

.form-progress {
    min-height: 5rem;
    height: 6rem;
    background-color: white;
    border-bottom-color: #DFE2E5;
    border-bottom-width: 0.8px;
    border-bottom-style: solid;
}

.formSteps {
    background-color: white;
    .formStepImg {
        background-color: #F5F5F5;
        // height: fit-content;
        img {
            max-width: 100%;
            width: 100%;
        }
    }
    .formData {
        display: flex;
        flex-direction: column;
        .formInfo {
            flex-grow: 1;
            .photoInput {
                width: 100%;
                height: 100%;
                aspect-ratio: 1;
                border-width: 2px;
                border-style: dashed;
                border-color: $primary;
            }
            .camera {
                width: 48px;
            }
            .uploadedImg {
                aspect-ratio: 1;
                object-fit: cover;
            }

            .delPhoto {
                // z-index: 10;
                position: absolute;
                top: 5px;
                right: 5px;
                font-size: 12px;
                margin-right: 5px;
            }

        }
    }
}

.helpIcon {
    max-width: 24px;
}

h2, .h2 {
    font-size: calc(1rem + 0.9vw);
}
h4, .h4 {
    font-size: calc(0.7rem + 0.9vw);
}

// @include media-breakpoint-up(xs) {
//     .formSteps {
//         .formStepImg {
//             img {
//                 max-height: 400px;
//             }
//         }
//     }

// }

// @include media-breakpoint-up(lg) {
//     .formSteps {
//         .formStepImg {
//             height: 100%;
//             img {
//                 max-height: none;
//             }
//         }
//     }
// }
