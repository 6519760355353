@font-face {
    font-family: roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: roboto;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../assets/fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
    font-family: roboto;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../assets/fonts/Roboto-Bold.ttf) format('truetype');
}

* {
    font-family: roboto;
    // font-size: 48px;
}